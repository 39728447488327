<ng-template>
  <div
    class="sat-popover-container"
    #focusTrapElement
    [ngClass]="_classList"
    [@transformPopover]="{ value: state, params: params }"
    (@transformPopover.done)="_onAnimationDone($event)"
  >
    <mat-card class="mat-elevation-z6 flex flex-col" [formGroup]="group" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
      <div fxHide></div>
      <div class="flex flex-col">
        <div class="flex items-center space-x-2">
          <ng-container *ngIf="mode === 'range_optional'">
            <button
              mat-icon-button
              [matTooltip]="'timer.time.changeInputMode' | translate"
              [matTooltipOptions]="{ hideOnClick: false }"
              (click)="stateVisibleMode = !stateVisibleMode"
              type="button"
            >
              <mat-icon>swap_horiz</mat-icon>
            </button>
          </ng-container>
          <ng-container
            *ngTemplateOutlet="
              mode === 'range'
                ? rangeModeTemplate
                : mode === 'range_optional'
                ? stateVisibleMode
                  ? rangeModeTemplate
                  : durationModeTemplate
                : mode === 'duration'
                ? durationModeTemplate
                : null
            "
          ></ng-container>
        </div>
        <mat-calendar
          [selected]="group.value.date"
          [startAt]="group.value.date"
          [dateFilter]="availableDates"
          (selectedChange)="updateDate($event)"
          color="primary"
          class="-mx-16px"
        ></mat-calendar>
        <div class="-mx-16px -mb-2 flex flex-row items-center justify-end space-x-2 px-2" *ngIf="returnOnSubmit">
          <button mat-button (click)="close(null)">
            {{ 'utils.cancel' | translate }}
          </button>
          <button
            mat-raised-button
            color="primary"
            [disabled]="group.invalid"
            (click)="close({ value: group.value, entity: item })"
          >
            {{ 'utils.save' | translate }}
          </button>
        </div>
      </div>
      <ng-template #rangeModeTemplate>
        <div class="mat-bd-5 -mb-2 -mt-2 flex flex-1 items-center space-x-4 overflow-hidden rounded-lg">
          <div class="flex flex-shrink-0 flex-grow items-center justify-between space-x-2">
            <div class="wflex-[0_0_70px]">
              <app-time-input
                formControlName="start"
                #startInp
                elementRef
                #startInpRef="nativeElement"
                (focus)="this.selectInput($event, startInpRef)"
                (click)="this.selectInput($event, startInpRef)"
                [displayFormat]="false"
                placeholder="00:00"
              >
                <ng-template
                  timeInputControl
                  let-control="control"
                  let-inputPlaceholder="inputPlaceholder"
                  let-name="name"
                >
                  <div class="text-center">
                    <input
                      type="text"
                      [matTooltip]="inputPlaceholder | translate"
                      matInput
                      [formControl]="control"
                      class="h-10"
                      [attr.cdkFocusInitial]="!focusFieldName || focusFieldName === name ? true : null"
                    />
                  </div>
                </ng-template>
              </app-time-input>
            </div>
            <mat-icon inline>arrow_forward</mat-icon>
            <div class="pb-0 wflex-[0_0_70px]">
              <app-time-input
                formControlName="end"
                #endInp
                elementRef
                #endInpRef="nativeElement"
                (focus)="this.selectInput($event, endInpRef)"
                (click)="this.selectInput($event, endInpRef)"
                [displayFormat]="false"
                placeholder="00:00"
              >
                <ng-template
                  timeInputControl
                  let-control="control"
                  let-inputPlaceholder="inputPlaceholder"
                  let-name="name"
                >
                  <div class="text-center">
                    <input
                      type="text"
                      [matTooltip]="inputPlaceholder | translate"
                      matInput
                      [formControl]="control"
                      class="h-10"
                      [attr.cdkFocusInitial]="focusFieldName === name ? true : null"
                    />
                  </div>
                </ng-template>
              </app-time-input>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #rangeOptModeTemplate>
        <div class="flex flex-col space-y-6">
          <ng-container *ngTemplateOutlet="durationModeTemplate"></ng-container>
          <mat-expansion-panel class="!-mx-16px !rounded-none !shadow-none" displayMode="flat">
            <mat-expansion-panel-header>Set Start/End</mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <div class="flex flex-shrink-0 flex-grow items-center justify-between space-x-2">
                <div class="wflex-[0_0_70px]">
                  <app-time-input
                    formControlName="start"
                    #startInp
                    elementRef
                    #startInpRef="nativeElement"
                    (focus)="this.selectInput($event, startInpRef)"
                    (click)="this.selectInput($event, startInpRef)"
                    [displayFormat]="false"
                    placeholder="00:00"
                  >
                    <ng-template
                      timeInputControl
                      let-control="control"
                      let-inputPlaceholder="inputPlaceholder"
                      let-name="name"
                    >
                      <div class="text-center">
                        <input
                          type="text"
                          [matTooltip]="inputPlaceholder | translate"
                          matInput
                          [formControl]="control"
                          class="h-10"
                          [attr.cdkFocusInitial]="!focusFieldName || focusFieldName === name ? true : null"
                        />
                      </div>
                    </ng-template>
                  </app-time-input>
                </div>
                <mat-icon inline>arrow_forward</mat-icon>
                <div class="pb-0 wflex-[0_0_70px]">
                  <app-time-input
                    formControlName="end"
                    #endInp
                    elementRef
                    #endInpRef="nativeElement"
                    (focus)="this.selectInput($event, endInpRef)"
                    (click)="this.selectInput($event, endInpRef)"
                    [displayFormat]="false"
                    placeholder="00:00"
                  >
                    <ng-template
                      timeInputControl
                      let-control="control"
                      let-inputPlaceholder="inputPlaceholder"
                      let-name="name"
                    >
                      <div class="text-center">
                        <input
                          type="text"
                          [matTooltip]="inputPlaceholder | translate"
                          matInput
                          [formControl]="control"
                          class="h-10"
                          [attr.cdkFocusInitial]="focusFieldName === name ? true : null"
                        />
                      </div>
                    </ng-template>
                  </app-time-input>
                </div>
              </div>
            </ng-template>
          </mat-expansion-panel>
        </div>
      </ng-template>
      <ng-template #durationModeTemplate>
        <div class="mat-bd-5 -mb-2 -mt-2 flex flex-1 items-center space-x-4 overflow-hidden rounded-lg">
          <div class="mat-bg-5 flex h-10 flex-shrink-0 items-center px-3.5" translate>timer.time.duration</div>
          <tg-duration-input-control
            formControlName="duration"
            #durInp
            elementRef
            #durInpRef="nativeElement"
            (focus)="this.selectInput($event, durInp)"
            (click)="this.selectInput($event, durInp)"
            displayFormat="HH:mm"
          >
            <ng-template
              durationInputControl
              let-control="control"
              let-inputPlaceholder="inputPlaceholder"
              let-name="name"
            >
              <div class="cursor-pointer text-center focus-within:cursor-text">
                <input
                  type="text"
                  matInput
                  [formControl]="control"
                  class="h-10"
                  [attr.cdkFocusInitial]="focusFieldName === name ? true : null"
                />
              </div>
            </ng-template>
          </tg-duration-input-control>
        </div>
      </ng-template>
    </mat-card>
  </div>
</ng-template>
