import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { createRxValue, distinctUntilChangedJson } from '@app/_helpers/utils';
import { MaterialScaleVariable } from '@app/animations/fade';
import { RecordToolbarService } from '@app/shared/record-toolbar/record-toolbar.service';
import { SatPopoverAnchoringService } from '@ncstate/sat-popover';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  addYears,
  differenceInSeconds,
  endOfYear,
  isValid as isValidDT,
  isWithinInterval,
  parse as parseDT,
  startOfDay,
  startOfYear,
  subYears,
} from 'date-fns/esm';
import { map } from 'rxjs/operators';
import { Logger, UserSettingsQuery } from 'timeghost-api';

import { TimeEditPopoverComponent } from '../time-edit-popover/time-edit-popover.component';

const log = new Logger('TimeRangeControlComponent');
const MaterialScale = MaterialScaleVariable(0.975);
export type TimeRangeValue = {
  start: string;
  end: string;
  recordStart?: Date;
  duration: string;
};
@UntilDestroy()
@Component({
  selector: 'tg-time-range-control',
  templateUrl: './time-range-control.component.html',
  styleUrls: ['./time-range-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeRangeControlComponent),
      multi: true,
    },
  ],
  animations: [MaterialScale],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TimeRangeControlComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  // control = new FormControl({});
  @Input('control')
  control: UntypedFormGroup = new UntypedFormGroup({});
  @Input('mode')
  mode: 'range' | 'range_optional' | 'duration';
  private parseValue(x: any) {
    if (this.control.invalid) return { date: new Date(), duration: 0 };
    let start = parseDT(x.start, 'HH:mm', new Date()),
      end = parseDT(x.end, 'HH:mm', new Date()),
      date = x.recordStart ?? new Date();
    if (!isValidDT(start)) start = null;
    if (!isValidDT(end)) end = null;
    let duration: number = differenceInSeconds(end, start).clampThis(0.0);
    let rangeDuration = duration;

    if (this.mode !== 'range')
      duration = ((x) => (x.getHours() * 60 + x.getMinutes()) * 60)(parseDT(x.duration, 'HH:mm', date)) ?? duration;
    return {
      start,
      end,
      date,
      duration,
      rangeDuration,
    };
  }
  controlValue$ = createRxValue(null);
  propagateChange: Function = () => {};
  propagateTouched: Function = () => {};
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
  get controlValue() {
    return this.parseValue(this.control.value);
  }
  availableDates = (d: Date) =>
    isWithinInterval(d, {
      start: startOfYear(subYears(new Date(), 1)),
      end: this.userSettingsQuery.getValue()?.workspace?.settings?.allowFutureTimeTracking
        ? endOfYear(addYears(new Date(), 1))
        : new Date(),
    });
  toggleStateVisibleMode() {
    this.stateVisibleMode = !this.stateVisibleMode;
  }
  updateDate(date: Date) {
    return this.control.patchValue({
      recordStart: startOfDay(date),
    });
  }
  @ViewChild('timeEditPopover', { static: true }) timeEditPopover: TimeEditPopoverComponent;
  constructor(
    private _elementRef: ElementRef,
    private _viewContainer: ViewContainerRef,
    private satPopoverAnchor: SatPopoverAnchoringService,
    private controlContainer: ControlContainer,
    private userSettingsQuery: UserSettingsQuery,
    private elRef: ElementRef,
    private fm: FocusMonitor,
    @Optional() private recordService: RecordToolbarService,
    private cdref: ChangeDetectorRef
  ) {}
  get anchorRef() {
    return this._elementRef;
  }
  @HostListener('document:keydown.escape')
  closeMenu() {
    this.showMenu.value = false;
  }
  @HostListener('document:click', ['$event'])
  private _outsideClick(ev: MouseEvent) {
    if (!this.elRef.nativeElement.contains(ev.target)) {
      this.showMenu.value = false;
    }
  }
  toggleEditMenu(field?: string) {
    const value = this.parseValue(this.control.value);
    this.timeEditPopover
      .toggle(this.anchorRef, {
        time: {
          start: (value.start ?? new Date()).toISOString(),
          end: (value.end ?? new Date()).toISOString(),
          date: (value.date as Date).toISOString(),
          timeDiff: value.duration ?? 0,
        },
        field,
        mode: this.mode,
      } as any)
      .afterClosed()
      .subscribe((x) => {
        if (x) {
          const { start, end, date, duration } = x;
          this.control.patchValue({
            start,
            end,
            recordStart: date,
            duration: duration ?? '00:00',
          });
        }
      });
  }
  writeValue(value: TimeRangeValue) {
    this.control.patchValue(
      {
        start: value.start,
        end: value.end,
        recordStart: value.recordStart ?? new Date(),
        duration: value.duration,
      },
      { emitEvent: false }
    );
    this.controlValue$.value = this.parseValue(value);
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled !== this.control.disabled) this.control[isDisabled ? 'disable' : 'enable']();
  }

  private _stateVisibleMode: boolean = true;
  public get stateVisibleMode(): boolean {
    return this._stateVisibleMode;
  }
  @Output()
  stateVisibleModeChange = new EventEmitter();
  @Input()
  public set stateVisibleMode(v: boolean) {
    this._stateVisibleMode = v;
    if (!v) this.control.patchValue({ start: '00:00', end: '00:00' });

    this.stateVisibleModeChange.emit(v);
  }
  showMenu = createRxValue(false);
  ngOnInit(): void {
    // log.debug(this.control, this.control.value);

    // this.satPopoverAnchor.anchor(this.timeEditPopover, this._viewContainer, this._elementRef);
    this.controlValue$.value = this.parseValue(this.control.value);
    this.fm
      .monitor(this.elRef, true)
      .pipe(
        map((x) => !!x),
        untilDestroyed(this)
      )
      .subscribe((x) => (this.showMenu.value = x));
  }
  ngAfterViewInit(): void {
    this.control.valueChanges.pipe(distinctUntilChangedJson()).subscribe((val) => {
      log.debug('events', this.propagateChange, this.propagateTouched);
      this.propagateTouched(val);
      this.propagateChange(val);

      this.controlValue$.value = this.parseValue(val);
    });
    const valueNow = this.control.value;
    if (this.mode === 'range_optional' && valueNow.start === '00:00' && valueNow.end === '00:00')
      this.stateVisibleMode = false;
  }
  @ViewChild('timeContextMenuTrigger', { static: true })
  timeContextMenu: MatMenuTrigger;

  timeContextMenuPosition = { x: '0px', y: '0px' };
  setHourDiff = this.recordService?.setHourDiff.bind(this.recordService);
  setMinutesDiff = this.recordService?.setMinutesDiff.bind(this.recordService);
  setToNow = this.recordService?.setToNow.bind(this.recordService);
  setWorkDay = this.recordService?.setWorkDay.bind(this.recordService);
  onTimeContextMenuTrigger(event: MouseEvent, item: RecordToolbarTimeContextData) {
    event.stopPropagation(), event.preventDefault();
    this.timeContextMenuPosition.x = event.clientX + 'px';
    this.timeContextMenuPosition.y = event.clientY + 'px';
    this.timeContextMenu.menuData = { $implicit: item };
    this.timeContextMenu.openMenu();
  }
}

interface RecordToolbarTimeContextData {
  time: string;
  prop: string;
}
